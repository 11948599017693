<script>
import Textfield from '@smui/textfield'
import FormField from '@smui/form-field'
import Tab, {Label} from '@smui/tab'
import TabBar from '@smui/tab-bar'
import Button from '@smui/button'
import timezones from '../data/tz'
import Select, {Option} from '@smui/select'
import Icon from '@smui/select/icon'
import Checkbox from '@smui/checkbox'

let name = localStorage.getItem('name') || ''
let org = localStorage.getItem('org') || ''
let role = localStorage.getItem('role') || ''
let workNumber = localStorage.getItem('workNumber') || ''
let cellNumber = localStorage.getItem('cellNumber') || ''
let email = localStorage.getItem('email') || ''
let client = localStorage.getItem('client') || 'Gmail'
let chosenTzDisplay = localStorage.getItem('chosenTzDisplay') || ''
let additional = localStorage.getItem('additional') || ''
let rememberInfo = JSON.parse(localStorage.getItem('rememberInfo'))

$: rememberInfo && localStorage.setItem('rememberInfo', rememberInfo)
$: rememberInfo && localStorage.setItem('name', name)
$: rememberInfo && localStorage.setItem('org', org)
$: rememberInfo && localStorage.setItem('role', role)
$: rememberInfo && localStorage.setItem('workNumber', workNumber)
$: rememberInfo && localStorage.setItem('cellNumber', cellNumber)
$: rememberInfo && localStorage.setItem('email', email)
$: rememberInfo && localStorage.setItem('client', client)
$: rememberInfo && localStorage.setItem('chosenTzDisplay', chosenTzDisplay)
$: rememberInfo && localStorage.setItem('additional', additional)
$: !rememberInfo && localStorage.clear()

async function copy() {
  const sig = document.querySelector('figure').innerHTML
  
  try {
    const data = [new ClipboardItem({ 'text/html': sig }), new ClipboardItem({ 'text/plain': sig })]

    await navigator.clipboard.write(data)
  } catch {
    document.addEventListener('copy', handleCopy)
    document.execCommand('copy')
    document.removeEventListener('copy', handleCopy)

    function handleCopy (event) {
      event.clipboardData.setData('text/html', sig)
      event.clipboardData.setData('text/plain', sig)
      
      event.preventDefault()
    }
  }
}
</script>

<style>
:global(body) {
  margin-right: 3rem;
  margin-left: 3rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
section {
  margin-top: 1rem;
  margin-bottom: 3rem;
}
form {
  margin-right: 2rem;
  width: 55ch;
  position: -webkit-sticky;
  position: sticky;
  top: .5rem;
  /* wanted this container to be scrollable so the demo never goes out of sight */
  max-height: 90vh;  /* Wanted the user to be able to scroll to very last input */
  overflow-y: auto;
  padding-right: 0.5rem;
}
form > :global(*) {
  margin-top: 1rem; /* This ensures the floating label for first input does not get cut off */
}
form :global(label) {
  width: 100%;
}
section {
  flex-grow: 1;
}
nav > header {
  padding-top: 3rem;
  padding-bottom: 1rem;
}
nav > ol {
  padding-bottom: 1rem;
  margin-bottom: 0;

  border-bottom: solid;
  border-color: #0b5e97;
  border-width: 2px;
  border-radius: 1rem;
}
nav > ol > li {
  margin-bottom: 1rem;
}
.mb-0 {
  margin-bottom: 0;
}
li + ol {
  list-style-type: lower-alpha;
}
figure {
  margin: 0;
  padding: 2rem;
  margin-bottom: 1rem;
}
.thumbnail {
  max-height: 3rem;
}
.social-logo {
  height: 30px;
  left: auto;
  right: 16px;
  position: absolute;
  top: 13px;
}
:global(.mr-1) {
  margin-right: .5rem;
}
</style>

<main>
  <header class="mdc-typography--headline5">Instructions</header>

  <ol class="mdc-typography--body1">
    <li>Fill in your contact info in the boxes on the left side (only the ones you want on the signature).</li>
    <li>If you want your information saved in your browser, check the box marked "save info for next time".</li>
  </ol>

  <div class="flex-row">
    <form>
      <Textfield bind:value={name} label="Name" input$autofocus variant="outlined" />
      <Textfield bind:value={role} label="Role" variant="outlined" />
      <Textfield bind:value={org} label="Organization / Unit" variant="outlined" />

      <Textfield bind:value={email} label="Email" variant="outlined" />
      <Textfield bind:value={workNumber} label="Work number" variant="outlined" />
      <Textfield bind:value={cellNumber} label="Cell number" variant="outlined" />

      <Select label="Timezone" bind:value={chosenTzDisplay} variant="outlined" withLeadingIcon>
        <span slot="icon"><Icon class="material-icons">language</Icon></span>
        <Option value=""></Option>
        {#each timezones as tz}
          <Option value={tz.name}>{tz.name} ({tz.offset})</Option>
        {/each}
      </Select>

      <FormField>
        <span slot="label">
          <img src={`https://sig.ywamcos.org/logo.png`} alt={`logo`} class="thumbnail">
        </span>
      </FormField>

      <Textfield textarea bind:value={additional} label="Additional info" />
    </form>

    <!-- this will abide by HTML email constraints so user can copy/paste into email client -->
    <section>
      <figure class="mdc-elevation--z3">
        <table style="border-spacing: 0">
          <tr>
            <td width="170px" style="padding-top: 0.4rem; vertical-align: top">
              <a href="https://www.ywamcos.org">
                <img src={`https://sig.ywamcos.org/logo.png`} alt={`logo`} width="150">
              </a>
            </td>
            <td style="font-family: 'Source Sans Pro',Verdana,sans-serif; padding-right: 1rem">
              <strong style="color: #0b5e97; font-size: large">{name}</strong>

              {#if role}
                <br>
                {role}
              {/if}

              {#if org}
                <br>
                {org}
              {/if}

              <br>
              <a href="https://www.ywamcos.org">YWAM Colorado Springs</a><br>
            </td>
            <td style="font-family: 'Source Sans Pro'">
              {#if email}
                <br>
                <span style="line-height: 1.5">{email}</span>
              {/if}

              {#if cellNumber}
                <br>
                <small><strong>M:</strong></small> <span style="line-height: 1.5">{cellNumber}</span>
              {/if}

              {#if workNumber}
                <br>
                <small><strong>O:</strong></small> <span style="line-height: 1.5">{workNumber}</span>
              {/if}

              {#if chosenTzDisplay}
                <br>
                <span style="line-height: 1.5">{chosenTzDisplay}</span>
              {/if}

              <pre style="margin-top: .25rem; font-family: 'Source Sans Pro'">
                {additional}
              </pre>
            </td>
          </tr>
        </table>
      </figure>

      <FormField>
        <Checkbox bind:checked={rememberInfo} />
        <span slot="label">Save info for next time</span>
      </FormField>

      <nav>
        <header class="mdc-typography--headline5">Gmail instructions</header>

        <ol class="mdc-typography--body1">
          <li>
            <Button on:click={copy} variant='raised' dense class="mr-1">
              Click here
            </Button>
            to copy the generated signature above
          </li>
          <li>In Gmail, click <em>Settings</em> (the gear-shaped icon,) in the top right corner.</li>
          <li>Select "See all Settings".  (This should open to the "General" tab.  If not, select that tab.)</li>
          <li>In the "Signature" section, select "Create new".</li>
          <li>Name your Signature and click "Create".</li>
          <li>In the "Signature" section to the right of the name you created, paste (Control-v) in the box.</li>
          <li>At the bottom of the page, click Save Changes.</li>
        </ol>
      </nav>
    </section>
  </div>
</main>
